import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import FilterModal from "../../components/filters/InventoryFilter";
import { Modal } from "react-bootstrap";
import type {
  PaginatedStockQueryData,
  StockCategoryQueryData,
  StockMutationData,
  StockQueryData,
  UnitOfMeasureQueryData,
} from "../../apis/interfaces/invitems";
import {
  stockQuery,
  stockQueryAll,
  unitOfMeasureQueryAll,
  stockCategoryQueryAll,
  stockMakeModelQueryAll,
} from "../../apis/queries/invitems";
import {
  createStockMutation,
  updateStockMutation,
} from "../../apis/mutations/invitems";
import * as Sentry from "@sentry/react";
import StockForm from "../../forms/StockForm";

import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import ReplenishForm from "../../forms/ReplenishForm";
import ReplenishMultipleForm from "../../forms/ReplenishMultipleForm";
import "../../styles/inventory.css";
import BasicFilterTable from "../../components/BasicFilterTable";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorFallback from "../../components/Error";
import { debounce } from "lodash";

interface StockTableProps {
  onAddNew: any;
  onEdit: any;
  stocks: any;
  setStockInitialValues: any;
  setShowStockModal: any;
  onReplenishMultiple: any;
  showEdit: boolean;
  setShowEdit: any;
  setShowReplenish: any;
  onReplenish: any;
  showReplenish: boolean;
  selectedRows: any;
  hndRowSelect: any;
  hndFilterOpen: any;
  onFilter: any;
  filterData: any;
  filterExplainer: ReactElement | undefined;
  limit: number;
  offset: number;
  onSetLimit: Function;
  onSetOffset: Function;
  onSetSort: Function;
  onSetSearchTerm: Function;
  onFilterCleared: Function;
  clearFilterFlag: boolean;
}

interface StockModalProps {
  show: boolean;
  showEdit: boolean;
  onCancel: Function;
  onOk: Function;
  initialValues: StockMutationData;
  setShowStockModal: any;
  setStockInitialValues: any;
  setShowEdit: any;
  stocks: any;
}

interface ReplenishModalProps {
  show: boolean;
  showEdit: boolean;
  onCancel: Function;
  onOk: Function;
  initialValues: StockMutationData;
  setShowStockModal: any;
  setStockInitialValues: any;
  setShowEdit: any;
  setShowReplenish: any;
  showReplenish: boolean;
  stocks: any;
}

interface ReplenishMultipleFormProps {
  onCancel: Function;
  onOk: Function;
  initialValues: StockMutationData;
  setStockInitialValues: any;
  setShowReplenishMultiple: any;
  showReplenishMultiple: boolean;
  selectedRows: StockMutationData[];
  setSelectedRows: any;
  stocks: any;
}

interface FilterDataProps {
  type: string;
  category: string;
  make: string;
  model: string;
  inStock: number;
  reorder: number;
  taxable_nj: boolean;
  taxable_ny: boolean;
  disposition: string;
  active: boolean;
  textsearch?: string;
  vendor: string;
}

interface FilterJsonIntermediateProps {
  type?: string;
  category?: string;
  make?: string;
  model?: string;
  inStock?: number;
  reorder?: number;
  taxable_nj?: boolean;
  taxable_ny?: boolean;
  disposition?: string;
  textsearch?: string;
  active?: boolean;
  vendor?: string;
}

const InvalidateStockQuery = (): JSX.Element => {
  const queryClient = useQueryClient();
  queryClient.invalidateQueries(["stockQueryAll", "stockMakeModelQueryAll"]);
  return <></>;
};

const StockModal = (props: StockModalProps) => {
  const { show, showEdit, onCancel, onOk, initialValues, stocks } = props;
  const queryClient = useQueryClient();
  const createStock = useMutation({
    ...createStockMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["stockQueryAll"] });
      onOk();
    },
  });
  const updateStockMutationFn = async (params: [number, StockMutationData]) => {
    const [id, data] = params;
    try {
      await updateStockMutation.mutationFn(id, data);
      onOk();
      InvalidateStockQuery();
      //stocks.refetch();
    } catch (error) {}
  };
  const makeModels = useQuery(stockMakeModelQueryAll());

  const updateStock = useMutation(updateStockMutationFn);

  const hndSubmit = async (values: StockMutationData) => {
    if (parseInt(values.make) === -1) {
      values.make = values.makeOther
        ? values.makeOther
        : "Other - Not Specified";
    }

    if (parseInt(values.model) === -1) {
      values.model = values.modelOther
        ? values.modelOther
        : "Other - Not Specified";
    }

    if (!values.id) {
      try {
        if (values.stock_type === "small-tool") {
          values.quantity_on_hand = 1;
        }

        await createStock.mutateAsync(values);
        toast.success("Stock added successfully");
      } catch (err) {
        toast.error("An error occurred. Please try again.");
      }
    } else {
      try {
        await updateStock.mutateAsync([values.id as number, values]);
        toast.success("Stock updated successfully");
      } catch (err) {
        toast.error("An error occurred while updating. Please try again.");
      }
    }
    stocks.refetch();
    makeModels.refetch();
  };
  const hndModalClose = () => {
    onCancel();
  };

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <Modal show={show} onCancel={hndModalClose}>
        {!showEdit && (
          <Modal.Header closeButton onClick={hndModalClose}>
            Add Stock
          </Modal.Header>
        )}
        {showEdit && (
          <Modal.Header closeButton onClick={hndModalClose}>
            Edit Stock
          </Modal.Header>
        )}
        <StockForm
          initialValues={initialValues}
          onCancel={hndModalClose}
          onSubmit={hndSubmit}
          show={show}
          showEdit={showEdit}
          makeModels={makeModels}
        />
      </Modal>
    </Sentry.ErrorBoundary>
  );
};

const ReplenishModal = (props: ReplenishModalProps) => {
  const {
    onCancel,
    onOk,
    initialValues,
    setShowReplenish,
    showReplenish,
    stocks,
  } = props;
  //Mutation functions

  const updateStockMutationFn = async (params: [number, StockMutationData]) => {
    const [id, data] = params;
    try {
      await updateStockMutation.mutationFn(id, data);
      onOk();
      InvalidateStockQuery();
    } catch (error) {}
  };

  const updateStock = useMutation(updateStockMutationFn);

  const hndSubmit = async (values: StockMutationData) => {
    try {
      await updateStock.mutateAsync([values.id as number, values]);
      toast.success("Replenishment successful");
    } catch (error) {
      toast.error("An error occurred while replenishing");
    }
    //stocks.refetch();
    InvalidateStockQuery();
  };
  const hndModalClose = () => {
    setShowReplenish(false);
    onCancel();
  };

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <Modal show={showReplenish} onCancel={hndModalClose}>
        <Modal.Header closeButton onClick={hndModalClose}>
          Replenish Stock
        </Modal.Header>
        <ReplenishForm
          initialValues={initialValues}
          onCancel={hndModalClose}
          onSubmit={hndSubmit}
        />
      </Modal>
    </Sentry.ErrorBoundary>
  );
};

const ReplenishMultipleModal = (props: ReplenishMultipleFormProps) => {
  const {
    onCancel,
    onOk,
    initialValues,
    setShowReplenishMultiple,
    showReplenishMultiple,
    selectedRows,
    setSelectedRows,
    stocks,
  } = props;

  const updateStockMutationFn = async (params: [number, StockMutationData]) => {
    const [id, data] = params;
    try {
      await updateStockMutation.mutationFn(id, data);
      onOk();
    } catch (error) {}
  };

  const updateStock = useMutation(updateStockMutationFn);

  const hndSubmit = async (values: StockMutationData) => {
    try {
      await updateStock.mutateAsync([values.id as number, values]);
      setShowReplenishMultiple(false);
      setSelectedRows([] as StockMutationData[]);
      onOk();
      stocks.refetch();
    } catch (error) {
      toast.error("An error occurred while replenishing");
    }
  };
  const hndModalClose = () => {
    setShowReplenishMultiple(false);
    onCancel();
  };
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <Modal
        show={showReplenishMultiple}
        onCancel={hndModalClose}
        dialogClassName="modal-xl"
      >
        <Modal.Header>Replenish Multiple</Modal.Header>
        <ReplenishMultipleForm
          initialValues={initialValues}
          onCancel={hndModalClose}
          onSubmit={hndSubmit}
          selectedRows={selectedRows}
        />
      </Modal>
    </Sentry.ErrorBoundary>
  );
};

const StockTable = (props: StockTableProps) => {
  const {
    onAddNew,
    onReplenishMultiple,
    stocks,
    setShowStockModal,
    setShowReplenish,
    setStockInitialValues,
    setShowEdit,
    selectedRows,
    hndRowSelect,
    hndFilterOpen,
    filterExplainer,
    limit,
    offset,
    onSetOffset,
    onSetLimit,
    onSetSort,
    onSetSearchTerm,
    onFilterCleared,
    clearFilterFlag,
  } = props;
  const [, setUnits] = useState<UnitOfMeasureQueryData[]>([]);
  const [displayData, setDisplayData] = useState<StockQueryData[]>([]);
  const { data: unit_data } = useQuery(unitOfMeasureQueryAll());

  const fireOnEdit = useCallback(
    (row: PaginatedStockQueryData) => {
      setStockInitialValues({
        id: row.id,
        stock_category: row.stock_category,
        stock_disposition: row.stock_disposition,
        stock_type: row.stock_type,
        stock: row.stock,
        lead_time: row.lead_time,
        taxable_ny: row.taxable_ny,
        taxable_nj: row.taxable_nj,
        quantity_on_hand: row.quantity_on_hand,
        reorder_point: row.reorder_point,
        reorder_quantity: row.reorder_quantity,
        unit_of_measure: row.unit_of_measure.id,
        unit_price: row.unit_price,
        vendor: row.vendor.id,
        active: row.active,
        created_by: row.created_by,
        serial: row.serial,
        rental_rate: row.rental_rate,
        ycc_serial: row.ycc_serial,
        make: row.make,
        model: row.model,
        case_quantity: row.case_quantity,
        acquisition_date: row.acquisition_date,
      });
      setShowEdit(true);
      setShowStockModal(true);
    },
    [setStockInitialValues, setShowEdit, setShowStockModal]
  );

  const fireOnReplenish = useCallback(
    (row: PaginatedStockQueryData) => {
      setStockInitialValues({
        id: row.id,
        stock_category: row.stock_category,
        stock_disposition: row.stock_disposition,
        stock_type: row.stock_type,
        stock: row.stock,
        lead_time: row.lead_time,
        taxable_ny: row.taxable_ny,
        taxable_nj: row.taxable_nj,
        quantity_on_hand: row.quantity_on_hand,
        reorder_point: row.reorder_point,
        reorder_quantity: row.reorder_quantity,
        unit_of_measure: row.unit_of_measure.id,
        unit_price: row.unit_price,
        vendor: row.vendor.id,
        active: row.active,
        created_by: row.created_by,
        serial: row.serial,
        rental_rate: row.rental_rate,
        ycc_serial: row.ycc_serial,
        make: row.make,
        model: row.model,
        acquisition_date: row.acquisition_date,
      });
      setShowReplenish(true);
    },
    [setStockInitialValues, setShowReplenish]
  );
  useEffect(() => {
    if (unit_data) {
      setUnits(unit_data);
    }

    if (stocks.data?.results) {
      setDisplayData(stocks.data.results);
    }
    const smallToolSelected = selectedRows.some(
      (row: StockQueryData) => row.stock_type === "small-tool"
    );
    setMultipleRowsSelected(selectedRows.length > 1 && !smallToolSelected);
  }, [unit_data, stocks.data, selectedRows]);
  const memoizedDisplayData = useMemo(() => displayData, [displayData]);
  const [multipleRowsSelected, setMultipleRowsSelected] = useState(false);

  const hndChangeSort = (column: any, direction: any) => {
    onSetSort(
      column.sortField ? column.sortField : "",
      direction === "asc" ? "asc" : "desc"
    );
  };

  const formattedPrice = (row: StockQueryData) => {
    const resolvedPrice = row.stock_type === 'consumable' ? Number(row.unit_price).toLocaleString("en-US", { style: "currency", currency: "USD" }) : Number(row.rental_rate * 8).toLocaleString("en-US", { style: "currency", currency: "USD" })

    return row.stock_type === 'consumable'? resolvedPrice : `${resolvedPrice}/day`
  }

  const columns = [
    {
      name: "",
      cell: (row: StockQueryData) => (
        <input
          type="checkbox"
          checked={selectedRows.includes(row)}
          onChange={() => hndRowSelect(row)}
        />
      ),
      width: "3%",
      maxWidtdh: "1em",
      compact: true,
    },
    {
      name: "ID",
      cell: (row: StockQueryData) => row.id,
      maxWidth: "5%",
      maxHeight: "1em",
      compact: true,
      sortable: true,
      sortField: "id",
    },
    {
      name: "Name",
      sortable: true,
      sortField: "stock",
      selector: (row: StockQueryData) => row.stock,
      cell: (row: StockQueryData) => (
        <>
          {row.stock}
          <br />
          {row.stock_type}
          <br />
          {row.stock_category && row.stock_category.parent_category ? (
            <>{row.stock_category.parent_category.stock_category} - </>
          ) : null}
          {row.stock_category ? (
            <>
              {row.stock_category.stock_category}
              <br />
            </>
          ) : null}
          {row.stock_type === "small-tool" ? (
            <>
              {"YCC Serial: "}
              {row.ycc_serial}
              <br />
            </>
          ) : null}
        </>
      ),
      minWidth: "20%",
      maxHeight: "1em",
      compact: true,
    },
    {
      name: "Price",
      cell: (row: StockQueryData) => `${formattedPrice(row)}`,
      maxWidth: "5%",
      maxHeight: "1em",
      compact: true,
      sortable: true,
      sortField: "unit_price",
    },
    {
      name: "Vendor",
      sortable: true,
      sortField: "vendor__vendor_name",
      selector: (row: PaginatedStockQueryData) => row.vendor.vendor_name,
      maxHeight: "1em",
      minWidth: "20%",

      compact: true,
    },

    {
      name: "On Hand",
      sortable: true,
      sortField: "quantity_on_hand",
      selector: (row: StockQueryData) => row.quantity_on_hand,
      maxHeight: "1em",
      compact: true,
    },
    {
      name: "Reorder At",
      sortable: true,
      sortField: "reorder_point",
      selector: (row: StockQueryData) => row.reorder_point,
      maxHeight: "1em",
      compact: true,
    },
    {
      name: "Reorder Qty",
      sortable: true,
      sortField: "reorder_quantity",
      selector: (row: StockQueryData) => row.reorder_quantity,
      maxHeight: "1em",
      compact: true,
    },
    {
      name: "Active",
      sortable: true,
      sortField: "active",
      selector: (row: StockQueryData) => row.active,
      cell: (row: StockQueryData) => (
        <>
          {row.active ? <span className="text-success">&#x2714;</span> : null}

          {!row.active && row.stock_type !== "small-tool" ? (
            <span className="text-danger">&#x2718;</span>
          ) : null}

          {!row.active &&
          row.stock_type === "small-tool" &&
          row.stock_disposition === "missing" ? (
            <span className="text-danger">MIA</span>
          ) : null}

          {!row.active &&
          row.stock_type === "small-tool" &&
          row.stock_disposition === "sold" ? (
            <span className="text-danger">SOLD</span>
          ) : null}

          {!row.active &&
          row.stock_type === "small-tool" &&
          row.stock_disposition === "repair" ? (
            <span className="text-danger">REPAIR</span>
          ) : null}

          {!row.active &&
          row.stock_type === "small-tool" &&
          row.stock_disposition === "inactive" ? (
            <span className="text-danger">&#x2718;</span>
          ) : null}
        </>
      ),
      maxHeight: "1em",
      compact: true,
    },
    {
      name: "Actions",
      cell: (row: PaginatedStockQueryData) => (
        <div className="action-menu">
          <button
            className="btn btn-sm btn-link"
            onClick={fireOnEdit.bind(this, row)}
          >
            Edit
          </button>
          {row.stock_type !== "small-tool" && (
            <button
              className="btn btn-sm btn-link"
              onClick={fireOnReplenish.bind(this, row)}
            >
              Replenish
            </button>
          )}
        </div>
      ),
      maxHeight: "1em",
      compact: true,
    },
  ];

  const filterButtons = (
    <>
      <button className="btn btn-link" onClick={hndFilterOpen}>
        Filter Results
      </button>
      <button className="btn btn-link" onClick={onAddNew}>
        Add New
      </button>
      {multipleRowsSelected && (
        <button className="btn btn-link" onClick={onReplenishMultiple}>
          Replenish Multiple
        </button>
      )}
    </>
  );

  return stocks.data.results ? (
    <div className="row">
      <div className="col-12">
        <div className="align-self-center mr-2"></div>
        <div className="p-1">
          <Sentry.ErrorBoundary fallback={ErrorFallback}>
            <BasicFilterTable
              tableKey="stock"
              title="Inventory"
              dataSource={memoizedDisplayData}
              columns={columns}
              filterButtons={filterButtons}
              filterExplainer={filterExplainer}
              exportFileName={"Inventory Export Data.csv"}
              tableCount={stocks.data.count}
              limit={limit}
              offset={offset}
              onChangeLimit={onSetLimit}
              onChangeOffset={onSetOffset}
              filterHnd={onSetSearchTerm}
              onFilterCleared={onFilterCleared}
              clearFilterFlag={clearFilterFlag}
              onChangeSort={hndChangeSort.bind(this)}
            />
          </Sentry.ErrorBoundary>
        </div>
      </div>
    </div>
  ) : null;
};

const blankStockForm = () => {
  return {
    stock_category: {
      id: 0,
      stock_category: "",
    },
    stock_disposition: "",
    stock_type: "",
    stock: "",
    lead_time: 0,
    taxable_ny: false,
    taxable_nj: false,
    quantity_on_hand: 0,
    reorder_point: 0,
    reorder_quantity: 0,
    unit_of_measure: {
      unit_of_measure: "",
    },
    unit_price: 0,
    vendor: 0,
    active: true,
    serial: "",
    rental_rate: 0,
    ycc_serial: "",
    make: "",
    model: "",
  };
};

const Stock = () => {
  const queryClient = useQueryClient();

  const [limit, setLimit] = useState<number>(25);
  const [offset, setOffset] = useState<number>(0);
  const [filterJson, setFilterJson] = useState<string>("");
  const [sortField, setSortField] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const stocks = useQuery(
    stockQueryAll(limit, offset, filterJson, sortField, sortDirection)
  );
  const categories = useQuery(stockCategoryQueryAll());

  const [showStockModal, setShowStockModal] = useState(false);
  const [showReplenish, setShowReplenish] = useState(false);
  const [showReplenishMultiple, setShowReplenishMultiple] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [loadStockId] = useState<undefined | number>(undefined);
  const [filterExplainer, setFilterExplainer] = useState<
    ReactElement | undefined
  >(undefined);

  const [selectedRows, setSelectedRows] = useState<StockMutationData[]>([]);
  const [stockInitialValues, setStockInitialValues] = useState(
    blankStockForm()
  );
  const [showFilter, setShowFilter] = useState(false);
  const [filterData] = useState({
    type: "",
    category: "",
    inStock: 0,
    reorder: 0,
    active: true,
    vendor: "",
  });
  const [clearTextFilter, setClearTextFilter] = useState(false);

  const hndFilterOpen = () => {
    setShowFilter(true);
  };

  const hndFilterClose = () => {
    setShowFilter(false);
  };

  const hndFilterClear = () => {
    setFilterJson(JSON.stringify({}));
    setOffset(0);

    setClearTextFilter(false);
    setClearTextFilter(true);
    setClearTextFilter(false);
    setClearTextFilter(true);
    formatFilterExplainer();
  };

  const hndTextFilterCleared = () => {
    setClearTextFilter(false);
    formatFilterExplainer();
  };

  const hndSetSort = (field: string, direction: string) => {
    setSortField(field);
    setSortDirection(direction);
  };

  const formatFilterExplainer = () => {
    try {
      const filterData = JSON.parse(filterJson);
      const explainerRow: Array<string> = [];

      if (filterData.category?.length) {
        if (categories.data) {
          const category = categories.data.find(
            (category: StockCategoryQueryData) =>
              category.id === parseInt(filterData.category) ||
              category.stock_category === filterData.category
          );

          if (category) {
            const parentCategory = category.parent_category
              ? `${category.parent_category.stock_category} - `
              : "";

            explainerRow.push(
              `Category "${parentCategory}${category.stock_category}"`
            );
          } else {
            explainerRow.push(`Category ${filterData.category}`);
          }
        } else {
          explainerRow.push(`Category ${filterData.category}`);
        }
      }
      if (filterData.vendor?.length) {
        explainerRow.push(`Vendor ${filterData.vendor}`);
      }

      if (filterData.inStock && filterData.inStock === 1) {
        explainerRow.push("In Stock");
      }
      if (filterData.inStock && filterData.inStock === 2) {
        explainerRow.push("Out of Stock");
      }

      if (filterData.reorder && filterData.reorder === 1) {
        explainerRow.push("Needs Reorder");
      }

      if (filterData.type && filterData.type.length > 0) {
        explainerRow.push(`Type ${filterData.type}`);
      }

      if (filterData.taxable_nj) explainerRow.push("Taxable(NJ)");

      if (filterData.taxable_ny) explainerRow.push("Taxable(NY)");

      if (filterData.textsearch && filterData.textsearch.length > 0)
        explainerRow.push(`Text Search "${filterData.textsearch}"`);

      if (filterData.make && filterData.make.length > 0)
        explainerRow.push(`Make "${filterData.make}"`);

      if (filterData.model && filterData.model.length > 0)
        explainerRow.push(`Model "${filterData.model}"`);

      if (filterData.disposition && filterData.disposition.length > 0)
        explainerRow.push(`Disposition "${filterData.disposition}"`);
      if (filterData.active) explainerRow.push(`Active "${filterData.active}"`);

      let clearButton = <></>;

      if (explainerRow.length > 0) {
        clearButton = (
          <>
            {" "}
            -{" "}
            <a className="btn-link" onClick={hndFilterClear}>
              Clear
            </a>
          </>
        );
      }

      setFilterExplainer(<>{explainerRow.join(", ")}</>);
    } catch (e) {
      setFilterExplainer(<></>);
    }
  };

  const hndFilterApply = (filterData: FilterDataProps) => {
    let newFilterJson: FilterJsonIntermediateProps = {};

    // Map the filterData to newFilterJson
    if (filterData.category) newFilterJson.category = filterData.category;
    if (filterData.vendor) newFilterJson.vendor = filterData.vendor;
    if (filterData.type) newFilterJson.type = filterData.type;
    if (filterData.make) newFilterJson.make = filterData.make;
    if (filterData.model) newFilterJson.model = filterData.model;
    if (filterData.inStock) newFilterJson.inStock = filterData.inStock;
    if (filterData.reorder) newFilterJson.reorder = filterData.reorder;
    if (filterData.taxable_nj) newFilterJson.taxable_nj = filterData.taxable_nj;
    if (filterData.taxable_ny) newFilterJson.taxable_ny = filterData.taxable_ny;
    if (filterData.disposition)
      newFilterJson.disposition = filterData.disposition;
    if (filterData.active !== undefined)
      newFilterJson.active = filterData.active; // Assuming active is a boolean
    if (filterData.textsearch) newFilterJson.textsearch = filterData.textsearch; // Assuming text search is handled separately

    // Update the filterJson state
    setFilterJson(JSON.stringify(newFilterJson));

    // Optionally, reset pagination
    setOffset(0);

    // Update filter explainer
    formatFilterExplainer(); // If this function depends on filterJson, it will update automatically. If not, you might need to pass newFilterJson to it.
  };


  // The following query gets a stock by ID and displays the modal for that stock.
  useQuery({
    queryFn: () => stockQuery,
    queryKey: ["stockQuery", loadStockId],
    enabled: !!loadStockId,
    onSuccess: (data: StockQueryData) => {
      setStockInitialValues(data);
      setShowStockModal(true);
    },
  });

  const hndAddNew = () => {
    setShowEdit(false);
    setStockInitialValues(blankStockForm());
    setShowStockModal(true);
  };
  //handles click on Replenish Multiple (multiple selected items)
  const hndReplenishMultiple = () => {
    setShowReplenishMultiple(true);
  };
  //handles click on Replenish Button (single item)
  const hndReplenish = () => {
    setShowReplenish(true);
  };

  const hndRowSelect = (row: StockMutationData) => {
    // Check if the row is already selected
    const isSelected = selectedRows.some(
      (selectedRow) => selectedRow.id === row.id
    );

    if (isSelected) {
      // Deselect the row
      const updatedRows = selectedRows.filter(
        (selectedRow) => selectedRow.id !== row.id
      );
      setSelectedRows(updatedRows);
    } else {
      // Select the row
      setSelectedRows([...selectedRows, row]);
    }
  };
  //handles a click on the edit button.
  const hndEdit = () => {
    setShowEdit(true);
    setShowStockModal(true);
    setStockInitialValues({
      ...stockInitialValues,
      stock_category: {
        id: 0,
        stock_category: "",
      },
      stock_disposition: "",
      stock_type: "",
      stock: "",
      lead_time: 0,
      taxable_ny: false,
      taxable_nj: false,
      quantity_on_hand: 0,
      reorder_point: 0,
      reorder_quantity: 0,
      unit_of_measure: {
        unit_of_measure: "",
      },
      unit_price: 0,
      vendor: 0,
      active: true,
      serial: "",
      rental_rate: 0,
      ycc_serial: "",
      make: "",
      model: "",
    });
  };

  const hndModelClose = () => {
    setShowStockModal(false);
    setShowReplenish(false);
    setShowReplenishMultiple(false);
    stocks.refetch();
  };

  const hndSetOffset = (newOffset: number) => {
    setOffset(newOffset);
  };

  const hndSetLimit = (newLimit: number) => {
    setLimit(newLimit);
  };
  const debouncedSetSearchTerm = useCallback(
    debounce((term) => {
      setFilterJson((prevFilterJson) => {
        const oldFilterJson = JSON.parse(prevFilterJson || "{}");
        oldFilterJson.textsearch = term.trim();
        return JSON.stringify(oldFilterJson);
      });
    }, 500),
    []
  ); // Dependencies array is now empty

  // Handler function remains the same
  const hndSetSearchTerm = (term: string) => {
    debouncedSetSearchTerm(term);
  };

  useEffect(() => {
    formatFilterExplainer();
    stocks.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, filterJson, sortField, sortDirection]);

  return (
    <Layout>
      <Loader isLoading={stocks.data === undefined}>
        <ToastContainer />
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
          {stocks.data?.results ? (
            <StockTable
              onAddNew={hndAddNew}
              onReplenishMultiple={hndReplenishMultiple}
              onEdit={hndEdit}
              onFilter={hndFilterOpen}
              stocks={stocks}
              setStockInitialValues={setStockInitialValues}
              setShowStockModal={setShowStockModal}
              setShowEdit={setShowEdit}
              showEdit={showEdit}
              onReplenish={hndReplenish}
              setShowReplenish={setShowReplenish}
              showReplenish={showReplenish}
              selectedRows={selectedRows}
              hndRowSelect={hndRowSelect}
              hndFilterOpen={hndFilterOpen}
              filterData={filterData}
              filterExplainer={filterExplainer}
              limit={limit}
              offset={offset}
              onSetOffset={hndSetOffset.bind(this)}
              onSetLimit={hndSetLimit.bind(this)}
              onSetSearchTerm={hndSetSearchTerm.bind(this)}
              onSetSort={hndSetSort.bind(this)}
              onFilterCleared={hndTextFilterCleared.bind(this)}
              clearFilterFlag={clearTextFilter}
            />
          ) : null}
        </Sentry.ErrorBoundary>
      </Loader>
      <ReplenishMultipleModal
        // Pass the selectedItems to the ReplenishMultipleForm component
        initialValues={stockInitialValues}
        selectedRows={selectedRows}
        onCancel={() => setShowReplenishMultiple(false)}
        showReplenishMultiple={showReplenishMultiple}
        onOk={hndModelClose}
        setStockInitialValues={setStockInitialValues}
        setShowReplenishMultiple={setShowReplenishMultiple}
        setSelectedRows={setSelectedRows}
        stocks={stocks}
      />
      <StockModal
        show={showStockModal}
        onOk={hndModelClose}
        onCancel={hndModelClose}
        initialValues={stockInitialValues}
        setShowStockModal={setShowStockModal}
        setStockInitialValues={setStockInitialValues}
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        stocks={stocks}
      />
      <ReplenishModal
        show={showReplenish}
        onOk={hndModelClose}
        onCancel={hndModelClose}
        initialValues={stockInitialValues}
        setShowStockModal={setShowStockModal}
        setStockInitialValues={setStockInitialValues}
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        setShowReplenish={setShowReplenish}
        showReplenish={showReplenish}
        stocks={stocks}
      />
      <FilterModal
        show={showFilter}
        onClose={hndFilterClose}
        onApply={hndFilterApply}
        onClear={hndFilterClear}
        setShowFilterModal={setShowFilter}
      />
    </Layout>
  );
};

export default Stock;
