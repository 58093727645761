import { apiClient } from "../client";

import type { MakeModelListItem } from "../interfaces/invitems";

const STALE_TIME = 1000 * 60 * 120; // Set stale time to 120 minutes

export const stockSimilarQuery = (id: number) => {
  return {
    queryKey: ["stockSimilarQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(`/invitems/stock_similar/?id=${id}`);
      return response.data;
    },
  };
};
// #region StockCategory Queries
export const stockCategoryQueryAll = () => {
  return {
    queryKey: ["stockCategoryQueryAll"],
    queryFn: async () => {
      const response = await apiClient.get("/invitems/stock_category/");
      return response.data;
    },
    staleTime: STALE_TIME,
  };
};

export const stockCategoryQuery = (id: string) => {
  return {
    queryKey: ["stockCategoryQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(`/invitems/stock_category/${id}/`);
      return response.data;
    },
  };
};
// #endregion

// #region UnitOfMeasure Queries
export const unitOfMeasureQueryAll = () => {
  return {
    queryKey: ["unitOfMeasureQueryAll"],
    queryFn: async () => {
      const response = await apiClient.get("/invitems/unitofmeasure/");
      return response.data;
    },
    staleTime: STALE_TIME,
  };
};

export const unitOfMeasureQuery = (id: string) => {
  return {
    queryKey: ["unitOfMeasureQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(`/invitems/unitofmeasure/${id}/`);
      return response.data;
    },
  };
};
// #endregion

// #region Stock Queries
export const stockQueryAll = (
  limit: number = 25,
  offset: number = 0,
  filter: string = "{}",
  sortField: string = "",
  sortDirection: string = "asc"
) => {
  const sanitizedFilter = filter.trim() ? filter : "{}";

  return {
    queryKey: ["stockQueryAll"],
    queryFn: async () => {
      // Exit early if limit or offset is invalid
      if (
        !Number.isInteger(limit) ||
        limit <= 0 ||
        !Number.isInteger(offset) ||
        offset < 0
      ) {
        console.warn("Query aborted: Invalid 'limit' or 'offset' value.");
        return;
      }

      let ordering = "";

      if (sortField.length > 0) {
        ordering = sortDirection === "desc" ? `-${sortField}` : sortField;
      }

      try {
        const response = await apiClient.get(
          `/invitems/stock/?limit=${limit}&offset=${offset}&filter=${encodeURIComponent(
            sanitizedFilter
          )}&ordering=${ordering}`
        );
        return response.data;
      } catch (error) {
        console.error("Failed to fetch stock data:", error);
        throw error;
      }
    },
  };
};

export const stockQueryAllNoPagination = () => {
  return {
    queryKey: ["stockQueryAll"],
    queryFn: async () => {
      const response = await apiClient.get("/invitems/stockall/");
      return response.data;
    },
  };
};

export const stockQuery = (id: string) => {
  return {
    queryKey: ["stockQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(`/invitems/stock/${id}/`);
      return response.data;
    },
  };
};

export const stockMetaQueryAll = () => {
  return {
    queryKey: ["stockMetaQueryAll"],
    queryFn: async () => {
      const response = await apiClient.get("/invitems/stock_meta/");
      return response.data;
    },
  };
};

export const stockFavoritesQuery = () => {
  return {
    queryKey: ["stockFavorites"],
    queryFn: async () => {
      const response = await apiClient.get(`/invitems/stock_favorites/`);
      return response.data;
    },
  };
};

// #endregion

// #region StockLocation Queries
export const stockLocationQueryAll = () => {
  return {
    queryKey: ["stockLocationQueryAll"],
    queryFn: async () => {
      const response = await apiClient.get("/invitems/stock_location/");
      return response.data;
    },
  };
};

export const stockLocationQuery = (id: string) => {
  return {
    queryKey: ["stockLocationQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(`/invitems/stock_location/${id}/`);
      return response.data;
    },
  };
};
// #endregion

// #region StockAdjustment Queries
export const stockAdjustmentQueryAll = () => {
  return {
    queryKey: ["stockAdjustmentQueryAll"],
    queryFn: async () => {
      const response = await apiClient.get("/invitems/stock_adjustment/");
      return response.data;
    },
  };
};

export const stockAdjustmentQuery = (id: string) => {
  return {
    queryKey: ["stockAdjustmentQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(`/invitems/stock_adjustment/${id}/`);
      return response.data;
    },
  };
};
// #endregion

// #region StockOrder Queries
export const stockOrderQueryAll = (
  limit: number = 25,
  offset: number = 0,
  filter: string = ""
) => {
  return {
    queryKey: ["stockOrderQueryAll"],
    queryFn: async () => {
      const response = await apiClient.get(
        `/invitems/stock_order/?limit=${limit}&offset=${offset}&filter=${filter}`
      );
      return response.data;
    },
  };
};

export const stockOrderQuery = (id: string) => {
  return {
    queryKey: ["stockOrderQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(`/invitems/stock_order/${id}/`);
      return response.data;
    },
  };
};
// #endregion

// #region StockOrderDetail Queries
export const stockOrderDetailQueryAll = () => {
  return {
    queryKey: ["stockOrderDetailQueryAll"],
    queryFn: async () => {
      const response = await apiClient.get("/invitems/stock_order_detail/");
      return response.data;
    },
  };
};

export const stockOrderDetailQuery = (id: string) => {
  return {
    queryKey: ["stockOrderDetailQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(
        `/invitems/stock_order_detail/${id}/`
      );
      return response.data;
    },
  };
};
export const stockOrderDetailLookup = (id: number) => {
  return {
    queryKey: ["stockOrderDetailQuery", id],
    queryFn: async () => {
      const response = await apiClient.get(
        `/invitems/stock_order_detail/?stock_order_id=${id}`
      );
      return response.data;
    },
  };
};
// #endregion

export const stockMakeModelQueryAll = () => {
  return {
    queryKey: ["stockMakeModelQueryAll"],
    queryFn: async () => {
      const response = await apiClient.get("/invitems/makemodels/");

      if (response.status === 200) {
        const fmtMakesModels: Array<MakeModelListItem> = [];

        response.data.forEach((elem: Array<string>) => {
          if (!elem[0] || !elem[1]) {
            return;
          }

          let makeIdx = fmtMakesModels.findIndex(
            (cmpElem: MakeModelListItem) => cmpElem.name === elem[0]
          );

          if (makeIdx === -1) {
            fmtMakesModels.push({ name: elem[0], children: [elem[1]] });
          } else {
            fmtMakesModels[makeIdx].children.push(elem[1]);
          }
        });

        return fmtMakesModels;
      }
    },
    staleTime: 1000 * 60 * 30, // 30 min stale time
  };
};
